export const ALBUMS_APP_DEF_ID = '13ff8629-c1fc-e289-e81f-bc8c8968e9d6';
export const ALBUMS_WIDGET_ID = '1413db53-d0ad-f2f2-f9a4-0dfc2e886b1e';
export const HOUR: number = 60 * 60 * 1000;
export enum API_GOG_STATE {
  PUBLISHED = 'PUBLISHED',
  SAVED = 'SAVED',
}

export const defaultStaticMediaUrls = {
  staticMediaUrl: 'https://static.wixstatic.com/media',
  mediaRootUrl: 'https://static.wixstatic.com/',
  staticVideoUrl: 'https://video.wixstatic.com/',
};

export const VIEW_MODES = {
  EDITOR: 'Editor',
};
